export default {
  documentTitle: {
    id: 'howToShareAndEngagePage.documentTitle',
    defaultMessage: 'How to Share and Engage - Choicely'
  },
  documentDescription: {
    id: 'howToShareAndEngagePage.documentDescription',
    defaultMessage: 'How to Share and Engage - Choicely'
  },
  title: {
    id: 'howToShareAndEngagePage.title',
    defaultMessage: 'How to Share and Engage'
  },
  imageLoaderError: {
    id: 'howToShareAndEngagePage.imageLoaderError',
    defaultMessage: 'Image load failed!'
  },
  subtitle: {
    id: 'howToShareAndEngagePage.subtitle',
    defaultMessage: 'Real time engagement on multiple platforms'
  },
  p1: {
    id: 'howToShareAndEngagePage.p1',
    defaultMessage:
      'Choicely content is designed to be both engaging and shareable, delivering stories that maximizes audience interaction. Content created on Choicely is optimized for the way people consume media today: it’s interactive, visual, mobile-friendly and personal.'
  },
  'recommended.title': {
    id: 'howToShareAndEngagePage.recommended.title',
    defaultMessage: 'Our 6 recommended contest posts:'
  },
  'recommended.announcement.title': {
    id: 'howToShareAndEngagePage.recommended.announcement.title',
    defaultMessage: '1. Pre-contest announcement'
  },
  'recommended.announcement.p1': {
    id: 'howToShareAndEngagePage.recommended.announcement.p1',
    defaultMessage:
      'Sometimes it´s good to create buzz before you publish a contest. Let your audience know something fun is coming up!'
  },
  'recommended.launch.title': {
    id: 'howToShareAndEngagePage.recommended.launch.title',
    defaultMessage: '2. New contest launch'
  },
  'recommended.launch.p1': {
    id: 'howToShareAndEngagePage.recommended.launch.p1',
    defaultMessage:
      'Announce your cool new contest and let your audience know about possible prizes, what happens to the winning participant and until when people can join.'
  },
  'recommended.statusUpdate.title': {
    id: 'howToShareAndEngagePage.recommended.statusUpdate.title',
    defaultMessage: '3. Status update'
  },
  'recommended.statusUpdate.p1': {
    id: 'howToShareAndEngagePage.recommended.statusUpdate.p1',
    defaultMessage:
      'Announce updates about the status of the voting. Who´s winning, trending, losing, etc. You can announce 1-5 updates during your contest.'
  },
  'recommended.joinCall.title': {
    id: 'howToShareAndEngagePage.recommended.joinCall.title',
    defaultMessage: '4. Still time to join'
  },
  'recommended.joinCall.p1': {
    id: 'howToShareAndEngagePage.recommended.joinCall.p1',
    defaultMessage:
      'Remind your audience they still have time to join your contest, make a difference and possibly win something.'
  },
  'recommended.lastCall.title': {
    id: 'howToShareAndEngagePage.recommended.lastCall.title',
    defaultMessage: '5. Last Call'
  },
  'recommended.lastCall.p1': {
    id: 'howToShareAndEngagePage.recommended.lastCall.p1',
    defaultMessage:
      'Give your audience the last chance to make a difference or win rewards. Usually the last hours are the busiest as people realise the time is running out.'
  },
  'recommended.winnerAnnouncement.title': {
    id: 'howToShareAndEngagePage.recommended.winnerAnnouncement.title',
    defaultMessage: '6. Winner announcement'
  },
  'recommended.winnerAnnouncement.p1': {
    id: 'howToShareAndEngagePage.recommended.winnerAnnouncement.p1',
    defaultMessage:
      'Make a post about the winners of the contest - say “Here are the top 10 dogs” or create some other interesting way to play the results. If you had rewards for participants, make sure to announce the winners.'
  },
  'recommended.winnerAnnouncement.p2': {
    id: 'howToShareAndEngagePage.recommended.winnerAnnouncement.p2',
    defaultMessage:
      'Remember to link your Choicely contest to each post. Simply use the share buttons or copy / paste the contest URL.'
  },
  'socialMedia.title': {
    id: 'howToShareAndEngagePage.socialMedia.title',
    defaultMessage: 'Social Media'
  },
  'socialMedia.p1': {
    id: 'howToShareAndEngagePage.socialMedia.p1',
    defaultMessage:
      'Sharing your content on social networks is easy. Once your contest is live, you can share it on all social networks to reach your audience. It goes without saying that you should be promoting your contest on social networks like Facebook, Twitter, Google+, Pinterest, etc. To maximise your reach, post, tweet and share updates about your contest to all social networks where you have presence. Hosting a contest is an excellent way to engage your fans.'
  },
  'socialMedia.p2': {
    id: 'howToShareAndEngagePage.socialMedia.p2',
    defaultMessage:
      'You can easily share any content on Choicely by clicking the social media icons. This is an effective way for your friends on other social networks to get notified of your actions on Choicely. If you are the organiser of the contest, we recommend you to share daily updates about your contest in social media.'
  },
  'website.title': {
    id: 'howToShareAndEngagePage.website.title',
    defaultMessage: 'Website'
  },
  'website.p1': {
    id: 'howToShareAndEngagePage.website.p1',
    defaultMessage:
      'Embed Choicely widget to your website. Your audience can join the contest on your website, campaign website, news article or a blog site.'
  },
  'banner.title': {
    id: 'howToShareAndEngagePage.banner.title',
    defaultMessage: 'Banner'
  },
  'banner.p1': {
    id: 'howToShareAndEngagePage.banner.p1',
    defaultMessage:
      'You can embed Choicely contests in banner sizes and use them as banners with a wide distribution.'
  },
  'blog.title': {
    id: 'howToShareAndEngagePage.blog.title',
    defaultMessage: 'Blog'
  },
  'blog.p1': {
    id: 'howToShareAndEngagePage.blog.p1',
    defaultMessage:
      'Write up a nicely detailed blog post about your contest and share the post with your online communities. Add the pertinent links to drive traffic from your blog post to your contest page.'
  },
  'email.title': {
    id: 'howToShareAndEngagePage.email.title',
    defaultMessage: 'Email'
  },
  'email.p1': {
    id: 'howToShareAndEngagePage.email.p1',
    defaultMessage:
      'Email is still one of the most effective ways of reaching your audience. Create an email newsletter about your contest to reach out to your current subscribers. Use your cover photo / images in your mail with links to the contest. This gives you the opportunity to promote your contest to your existing fans and allows your customers to engage with your brand.'
  },
  'application.title': {
    id: 'howToShareAndEngagePage.application.title',
    defaultMessage: 'Application'
  },
  'application.p1': {
    id: 'howToShareAndEngagePage.application.p1',
    defaultMessage:
      'Voting on Choicely application is super fun. Ask your audience to download Choicely apps to enjoy even richer voting experience. Your followers on Choicely who have Choicely application on their smartphones will get a push notification each time you make a new contest boosted with contest updates. This way your real time engagement through contests will jump to a whole new level.'
  },
  'widget.title': {
    id: 'howToShareAndEngagePage.widget.title',
    defaultMessage: 'Widget'
  },
  'widget.p1.prefix': {
    id: 'howToShareAndEngagePage.widget.p1.prefix',
    defaultMessage: 'Read more about'
  },
  'widget.p1.suffix': {
    id: 'howToShareAndEngagePage.widget.p1.suffix',
    defaultMessage: 'how to embed a widget'
  },
  'widget.p1.ending': {
    id: 'howToShareAndEngagePage.widget.p1.ending',
    defaultMessage: 'in our next article.'
  },
  'questions.title': {
    id: 'howToShareAndEngagePage.questions.title',
    defaultMessage: 'More questions?'
  },
  'questions.p1': {
    id: 'howToShareAndEngagePage.questions.p1',
    defaultMessage:
      'Let us know if you have any further questions about how to share & engage. Feel free to talk to us, we are here for you.'
  },
  getStartedButton: {
    id: 'howToShareAndEngagePage.getStartedButton',
    defaultMessage: 'Get Started Now!'
  }
}
