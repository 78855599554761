export default {
  documentTitle: {
    id: 'howToCreateBrandPage.documentTitle',
    defaultMessage: 'How to Create Brand - Choicely'
  },
  documentDescription: {
    id: 'howToCreateBrandPage.documentDescription',
    defaultMessage: 'How to Create Brand - Choicely'
  },
  title: {
    id: 'howToCreateBrandPage.title',
    defaultMessage: 'How to Create Brand'
  },
  imageLoaderError: {
    id: 'howToCreateBrandPage.imageLoaderError',
    defaultMessage: 'Image load failed!'
  },
  subtitle: {
    id: 'howToCreateBrandPage.subtitle',
    defaultMessage: 'Setting up a Brand page on Choicely is quick and easy.'
  },
  p1: {
    id: 'howToCreateBrandPage.p1',
    defaultMessage:
      'Choicely helps your large, medium or small business grow immediately.<br/>Anyone can create a brand page and start creating contests in minutes for free.'
  },
  p2: {
    id: 'howToCreateBrandPage.p2',
    defaultMessage:
      "To create a Brand or company page, you'll need to be logged in to your Choicely account.<br/>Once you have created an account, you can create and manage up to ten brand pages through your profile. Setting up a brand page is quick and easy. In just a few clicks, you can create a company profile for your business, brand or organisation and start creating contests for free. Below you find the easy steps to get the page up and running"
  },
  'p3.item1': {
    id: 'howToCreateBrandPage.p3.item1',
    defaultMessage:
      '1. Once you have logged in, tap the menu selection from the top right corner.'
  },
  'p3.item2': {
    id: 'howToCreateBrandPage.p3.item2',
    defaultMessage: '2. From menu select Create a Brand Page to get started.'
  },
  p4: {
    id: 'howToCreateBrandPage.p4',
    defaultMessage:
      'You will be directed to a “create a brand page” view. Here you need to fill the following info regarding your brand.'
  },
  'fillIn.item1': {
    id: 'howToCreateBrandPage.fillIn.item1',
    defaultMessage:
      '1. Add a brand profile picture from your computer or device.'
  },
  'fillIn.item2': {
    id: 'howToCreateBrandPage.fillIn.item2',
    defaultMessage:
      '2. Add a brand background picture from your computer or device.'
  },
  'fillIn.item3': {
    id: 'howToCreateBrandPage.fillIn.item3',
    defaultMessage:
      '3. Add your brand page name. This name will be automatically your unique and individual Choicely URL link.'
  },
  'fillIn.item4': {
    id: 'howToCreateBrandPage.fillIn.item4',
    defaultMessage:
      '4. Add a description of your brand. You have maximum of 150 marks to tell what your brand is about.'
  },
  'fillIn.item5': {
    id: 'howToCreateBrandPage.fillIn.item5',
    defaultMessage:
      '5. Add admin(s) if you want. You can ask any user who uses Choicely to become an admin to your brand page. This and these admins have the same rights to the brand page than you.'
  },
  'fillIn.item6': {
    id: 'howToCreateBrandPage.fillIn.item6',
    defaultMessage: '6. Click Save Info and your Page is ready to go.'
  },
  p5: {
    id: 'howToCreateBrandPage.p5',
    defaultMessage:
      'It is as simple as that! Now you have directed to your new brand page and you can immediately start creating your first contest. See an example of a new brand page below. You also have an unique URL link for your brand page that you can share with your audience anywhere anytime (www.choicely.com/yourpage).'
  },
  p6: {
    id: 'howToCreateBrandPage.p6',
    defaultMessage: 'Start creating your first contest by clicking “Create”.'
  },
  p7: {
    id: 'howToCreateBrandPage.p7',
    defaultMessage:
      "Now that you've created a brand Page, you're the administrator and can control the content and settings on your Page with your Page Admin Tools. You can now easily start to organise contests through your brand page. Read our next aricle on how to create a contest on Choicely."
  },
  getStartedButton: {
    id: 'howToCreateBrandPage.getStartedButton',
    defaultMessage: 'Get Started Now!'
  }
}
