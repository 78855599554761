import React, { Component } from 'react'
import DocumentMeta from 'react-document-meta'
import { Link } from 'gatsby'
import config from 'config'

import FormattedMessage from '@/formatted-message'
import Button from '@/button'

import intlMessages from './messages'
import banner from './images/fmarketing__banner--big.jpg'
import shareFb from './images/how-to-share__fb.jpg'
import shareWidget from './images/how-t0-share__widget.jpg'
import shareEmail from './images/how-to-share__email.jpg'
import beautyStep1 from './images/fbeauty__step-1.jpg'
import beautyStep2 from './images/fbeauty__step-2.jpg'

class HowToShareAndEngagePage extends Component {
  render() {
    const page = {
      meta: {
        property: { 'og:image': banner }
      }
    }

    return (
      <>
        <DocumentMeta
          title={intlMessages.documentTitle.defaultMessage}
          description={intlMessages.documentDescription.defaultMessage}
          meta={page.meta}
          extend
        />
        <img src={banner} alt="" className="mb3 center db" />

        <h3>
          <FormattedMessage {...intlMessages.subtitle} />
        </h3>
        <p>
          <FormattedMessage {...intlMessages.p1} />
        </p>
        <h3>
          <FormattedMessage {...intlMessages['recommended.title']} />
        </h3>
        <h4>
          <FormattedMessage
            {...intlMessages['recommended.announcement.title']}
          />
        </h4>
        <p>
          <FormattedMessage {...intlMessages['recommended.announcement.p1']} />
        </p>
        <h4>
          <FormattedMessage {...intlMessages['recommended.launch.title']} />
        </h4>
        <p>
          <FormattedMessage {...intlMessages['recommended.launch.p1']} />
        </p>
        <h4>
          <FormattedMessage
            {...intlMessages['recommended.statusUpdate.title']}
          />
        </h4>
        <p>
          <FormattedMessage {...intlMessages['recommended.statusUpdate.p1']} />
        </p>
        <h4>
          <FormattedMessage {...intlMessages['recommended.joinCall.title']} />
        </h4>
        <p>
          <FormattedMessage {...intlMessages['recommended.joinCall.p1']} />
        </p>
        <h4>
          <FormattedMessage {...intlMessages['recommended.lastCall.title']} />
        </h4>
        <p>
          <FormattedMessage {...intlMessages['recommended.lastCall.p1']} />
        </p>
        <h4>
          <FormattedMessage
            {...intlMessages['recommended.winnerAnnouncement.title']}
          />
        </h4>
        <p>
          <FormattedMessage
            {...intlMessages['recommended.winnerAnnouncement.p1']}
          />
        </p>
        <p>
          <FormattedMessage
            {...intlMessages['recommended.winnerAnnouncement.p2']}
          />
        </p>
        <h3>
          <FormattedMessage {...intlMessages['socialMedia.title']} />
        </h3>
        <p>
          <FormattedMessage {...intlMessages['socialMedia.p1']} />
        </p>
        <p>
          <FormattedMessage {...intlMessages['socialMedia.p2']} />
        </p>
        <img src={shareFb} alt="" className="mb3 center db" />

        <h3>
          <FormattedMessage {...intlMessages['website.title']} />
        </h3>
        <p>
          <FormattedMessage {...intlMessages['website.p1']} />
        </p>
        <img src={shareWidget} alt="" className="mb3 center db" />

        <h3>
          <FormattedMessage {...intlMessages['banner.title']} />
        </h3>
        <p>
          <FormattedMessage {...intlMessages['banner.p1']} />
        </p>
        <h3>
          <FormattedMessage {...intlMessages['blog.title']} />
        </h3>
        <p>
          <FormattedMessage {...intlMessages['blog.p1']} />
        </p>
        <h3>
          <FormattedMessage {...intlMessages['email.title']} />
        </h3>
        <p>
          <FormattedMessage {...intlMessages['email.p1']} />
        </p>
        <img src={shareEmail} alt="" className="mb3 center db" />
        <h3>
          <FormattedMessage {...intlMessages['application.title']} />
        </h3>
        <p>
          <FormattedMessage {...intlMessages['application.p1']} />
        </p>

        <div className="flex justify-center">
          <div className="ph2"><img src={beautyStep1} alt="" className="mb3" /></div>
          <div className="ph2"><img src={beautyStep2} alt="" className="mb3" /></div>
        </div>
        <h3>
          <FormattedMessage {...intlMessages['widget.title']} />
        </h3>
        <p>
          <FormattedMessage {...intlMessages['widget.p1.prefix']} />{' '}
          <Link className="link" to="/about/how-to-embed-widget">
            <FormattedMessage {...intlMessages['widget.p1.suffix']} />
          </Link>{' '}
          <FormattedMessage {...intlMessages['widget.p1.ending']} />
        </p>
        <h3>
          <FormattedMessage {...intlMessages['questions.title']} />
        </h3>
        <p>
          <FormattedMessage {...intlMessages['questions.p1']} />
        </p>
        <Button
          href={`${config.adminHostUrl}/brand/create?walkthrough=1`}
          color="green"
        >
          <FormattedMessage {...intlMessages.getStartedButton} />
        </Button>
      </>
    )
  }
}

export default HowToShareAndEngagePage
