import React, { Component } from 'react'
import DocumentMeta from 'react-document-meta'

import config from 'config'
import Button from '@/button'
import FormattedMessage from '@/formatted-message'

import intlMessages from './messages'
import widgetBuilder from './images/widget_builder.png'

class HowToEmbedWidgetPage extends Component {
  render() {
    const page = {
      meta: {
        property: { 'og:image': '/assets/how-to-embed-widget.jpg' }
      }
    }

    return (
      <>
        <DocumentMeta
          title={intlMessages.documentTitle.defaultMessage}
          description={intlMessages.documentDescription.defaultMessage}
          meta={page.meta}
          extend
        />

        <h3>
          <FormattedMessage {...intlMessages.subtitle} />
        </h3>
        <p>
          <FormattedMessage {...intlMessages.p1} />
        </p>
        <img src={widgetBuilder} alt="" className="mb3" />

        <Button href={`${config.adminHostUrl}/brand/create`} color="green">
          <FormattedMessage {...intlMessages.getStartedButton} />
        </Button>
      </>
    )
  }
}

export default HowToEmbedWidgetPage
