export default {
  documentTitle: {
    id: 'howToCreateContestPage.documentTitle',
    defaultMessage: 'How to Create a Contest - Choicely'
  },
  documentDescription: {
    id: 'howToCreateContestPage.documentDescription',
    defaultMessage: 'How to Create a Contest - Choicely'
  },
  title: {
    id: 'howToCreateContestPage.title',
    defaultMessage: 'How to Create a Contest'
  },
  imageLoaderError: {
    id: 'howToCreateContestPage.imageLoaderError',
    defaultMessage: 'Image load failed!'
  },
  subtitle: {
    id: 'howToCreateContestPage.subtitle',
    defaultMessage: 'Creating a contest on Choicely takes only minutes.'
  },
  p1: {
    id: 'howToCreateContestPage.p1',
    defaultMessage:
      'Anyone can create a contest on Choicely in minutes for free. Each user or brand can have up to 10 (ten) contest running at the same time. Creating a contest on Choicely is like creating an event on Facebook. Simple, fast and easy.'
  },
  p2: {
    id: 'howToCreateContestPage.p2',
    defaultMessage:
      'You can find “Create contest” in many places around Choicely platform. Either Tap menu from the top right corner and choose “Create contest” from there. You can also find “Add contest” button on top right of the “My contest” board.'
  },
  p3: {
    id: 'howToCreateContestPage.p3',
    defaultMessage:
      'Now you are in the “Create contest” view. Yes, we know, it is a lot of stuff here that you can add or modify. So, let’s go through all the options from the top to the bottom.'
  },
  'contestOwner.title': {
    id: 'howToCreateContestPage.contestOwner.title',
    defaultMessage: 'Contest owner'
  },
  'contestOwner.p1': {
    id: 'howToCreateContestPage.contestOwner.p1',
    defaultMessage:
      'Here you choose, who is running the contest. In case you have created a brand page, here you can change the contest owner to be your brand.'
  },
  'contestOwner.p2.heading': {
    id: 'howToCreateContestPage.contestOwner.p2.heading',
    defaultMessage: 'Cover photo.'
  },
  'contestOwner.p2.body': {
    id: 'howToCreateContestPage.contestOwner.p2.body',
    defaultMessage:
      'Here you set up the contest cover photo for your contest. This photo will be shown in the contest feed and social media, so pay extra caution that you choose an attractive photo for your contest.'
  },
  'primaryColor.title': {
    id: 'howToCreateContestPage.primaryColor.title',
    defaultMessage: 'Primary color'
  },
  'primaryColor.p1': {
    id: 'howToCreateContestPage.primaryColor.p1',
    defaultMessage:
      'Here you can play with different primary colors for your contest. <br/> Just press the button and choose from any color to finalise your cover photo background'
  },
  'contestName.title': {
    id: 'howToCreateContestPage.contestName.title',
    defaultMessage: 'Contest name'
  },
  'contestName.p1': {
    id: 'howToCreateContestPage.contestName.p1',
    defaultMessage:
      'Here you type your contest name. Remember to make it very attractive that it stands out from all the other contests in the contest feed.'
  },
  'description.title': {
    id: 'howToCreateContestPage.description.title',
    defaultMessage: 'Description'
  },
  'description.p1': {
    id: 'howToCreateContestPage.description.p1',
    defaultMessage:
      'Write a short, understandable description of the contest prize and steps to enter. Don’t leave people guessing what your contest is about!'
  },
  'contestType.title': {
    id: 'howToCreateContestPage.contestType.title',
    defaultMessage: 'Contest type'
  },
  'contestType.p1': {
    id: 'howToCreateContestPage.contestType.p1',
    defaultMessage:
      'On Choicely you can create and manage two different kind of contests.'
  },
  'contestType.p2.item1': {
    id: 'howToCreateContestPage.contestType.p2.item1',
    defaultMessage:
      '1. Vote contest. In a vote contest only you add photos and people vote.'
  },
  'contestType.p2.item2': {
    id: 'howToCreateContestPage.contestType.p2.item2',
    defaultMessage:
      '2. Photo contest. In a photo contest everyone can add photos and are able to vote.'
  },
  'addContestPhotos.title': {
    id: 'howToCreateContestPage.addContestPhotos.title',
    defaultMessage: 'Add contest photos'
  },
  'addContestPhotos.p1': {
    id: 'howToCreateContestPage.addContestPhotos.p1',
    defaultMessage:
      'Here you add the photos that you want people to vote. Minimum amount of photos is two and maximum amount is one hundred. Select beautiful images to get maximum attention from your audience.'
  },
  'contestCategories.title': {
    id: 'howToCreateContestPage.contestCategories.title',
    defaultMessage: 'Contest categories'
  },
  'contestCategories.p1': {
    id: 'howToCreateContestPage.contestCategories.p1',
    defaultMessage:
      'Help people to find your contest better. Choose from ten different categories to which your contest belongs into. This attracts people who are interested in your category to find your contest. Add up to 3 cathegories.'
  },
  'endDate.title': {
    id: 'howToCreateContestPage.endDate.title',
    defaultMessage: 'End date and time'
  },
  'endDate.p1': {
    id: 'howToCreateContestPage.endDate.p1',
    defaultMessage:
      'Here you can choose the length of your contest from anywhere from a few minutes to several months.'
  },
  'endDate.p2': {
    id: 'howToCreateContestPage.endDate.p2',
    defaultMessage:
      '+Edit start date and time. In case you want to set up a different start time for your contest, you can adjust the start date and time here.'
  },
  'advancedOptions.title': {
    id: 'howToCreateContestPage.advancedOptions.title',
    defaultMessage: 'Advanced'
  },
  'advancedOptions.country.title': {
    id: 'howToCreateContestPage.advancedOptions.country.title',
    defaultMessage: 'Country'
  },
  'advancedOptions.country.p1': {
    id: 'howToCreateContestPage.advancedOptions.country.p1',
    defaultMessage:
      'Here you can decide, who can see your contest. If you select global, any Choicely user from anywhere can see and join it. Another option is limit the visibility to selected countires. In this option only the users from selected countries can see the contest.'
  },
  'advancedOptions.freeVotes.title': {
    id: 'howToCreateContestPage.advancedOptions.freeVotes.title',
    defaultMessage: 'Number of free votes'
  },
  'advancedOptions.freeVotes.p1': {
    id: 'howToCreateContestPage.advancedOptions.freeVotes.p1',
    defaultMessage:
      'Here you choose how many votes each participant can give. Usually contests give one vote per participant but you can also give people a cutom or an unlimited amount of votes.'
  },
  'advancedOptions.applauseMode.title': {
    id: 'howToCreateContestPage.advancedOptions.applauseMode.title',
    defaultMessage: 'Applause mode'
  },
  'advancedOptions.applauseMode.p1': {
    id: 'howToCreateContestPage.advancedOptions.applauseMode.p1',
    defaultMessage:
      'By putting applause mode on, you give everyone right to give as many votes as they want. Let the game begin!'
  },
  'advancedOptions.paidVotes.title': {
    id: 'howToCreateContestPage.advancedOptions.paidVotes.title',
    defaultMessage: 'Paid votes'
  },
  'advancedOptions.paidVotes.p1': {
    id: 'howToCreateContestPage.advancedOptions.paidVotes.p1',
    defaultMessage:
      'Here you can allow people to buy extra votes for their favourites after they have run out of their free votes.'
  },
  'advancedOptions.privacy.title': {
    id: 'howToCreateContestPage.advancedOptions.privacy.title',
    defaultMessage: 'Privacy'
  },
  'advancedOptions.privacy.p1': {
    id: 'howToCreateContestPage.advancedOptions.privacy.p1',
    defaultMessage:
      'Here you can choose whether you want your contest to be public or private among specific users.'
  },
  'advancedOptions.unvotes.title': {
    id: 'howToCreateContestPage.advancedOptions.unvotes.title',
    defaultMessage: 'Unvotes'
  },
  'advancedOptions.unvotes.p1': {
    id: 'howToCreateContestPage.advancedOptions.unvotes.p1',
    defaultMessage:
      'Here you can choose if participants can unvote. This means they change their minds and take their votes back to vote for another contestant.'
  },
  'advancedOptions.showStatistics.title': {
    id: 'howToCreateContestPage.advancedOptions.showStatistics.title',
    defaultMessage: 'Show Statistics'
  },
  'advancedOptions.showStatistics.p1.title': {
    id: 'howToCreateContestPage.advancedOptions.showStatistics.p1.title',
    defaultMessage:
      'Here you can choose when your audience can see the statistics of the voting.'
  },
  'advancedOptions.showStatistics.p1.item1': {
    id: 'howToCreateContestPage.advancedOptions.showStatistics.p1.item1',
    defaultMessage:
      '1. After they have voted - People will see the current statistics once they have voted'
  },
  'advancedOptions.showStatistics.p1.item2': {
    id: 'howToCreateContestPage.advancedOptions.showStatistics.p1.item2',
    defaultMessage: '2. All the time - People see the statistics all the time'
  },
  'advancedOptions.showStatistics.p1.item3': {
    id: 'howToCreateContestPage.advancedOptions.showStatistics.p1.item3',
    defaultMessage:
      '3. After contest - People will see the statistics when the contest has finished (when the timer hits zero).'
  },
  'advancedOptions.showStatistics.p2': {
    id: 'howToCreateContestPage.advancedOptions.showStatistics.p2',
    defaultMessage:
      'By pressing “Publish” your contest will go live. After publishing your contest, you´ll be able to engage your audience by sharing status updates about your contest on social media and by embedding the contest to your website. You can still edit your contest once it´s published.'
  },
  'advancedOptions.publish.title': {
    id: 'howToCreateContestPage.advancedOptions.publish.title',
    defaultMessage: 'Publish'
  },
  'advancedOptions.publish.p1.prefix': {
    id: 'howToCreateContestPage.advancedOptions.publish.p1.prefix',
    defaultMessage: 'Now your contest is live. Read more about'
  },
  'advancedOptions.publish.p1.suffix': {
    id: 'howToCreateContestPage.advancedOptions.publish.p1.suffix',
    defaultMessage: 'how to share & engage'
  },
  'advancedOptions.publish.p1.ending': {
    id: 'howToCreateContestPage.advancedOptions.publish.p1.ending',
    defaultMessage: 'in our next article.'
  },
  'advancedOptions.questions.title': {
    id: 'howToCreateContestPage.advancedOptions.questions.title',
    defaultMessage: 'More questions?'
  },
  'advancedOptions.questions.p1': {
    id: 'howToCreateContestPage.advancedOptions.questions.p1',
    defaultMessage:
      'Let us know if you have any further questions about how to create a contest. Feel free to talk to us, we are here for you.'
  },
  getStartedButton: {
    id: 'howToCreateContestPage.getStartedButton',
    defaultMessage: 'Get Started Now!'
  }
}
