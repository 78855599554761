export default {
  documentTitle: {
    id: 'HowToPage.documentTitle',
    defaultMessage: 'How-to - Choicely'
  },
  documentDescription: {
    id: 'HowToPage.documentDescription',
    defaultMessage: 'Here you can find how Choicely works'
  }
}
