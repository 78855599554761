export default {
  documentTitle: {
    id: 'howToEmbedWidgetPage.documentTitle',
    defaultMessage: 'How to Embed Widget - Choicely'
  },
  documentDescription: {
    id: 'howToEmbedWidgetPage.documentDescription',
    defaultMessage: 'How to Embed Widget - Choicely'
  },
  title: {
    id: 'howToEmbedWidgetPage.title',
    defaultMessage: 'How to Embed Widget'
  },
  imageLoaderError: {
    id: 'howToEmbedWidgetPage.imageLoaderError',
    defaultMessage: 'Image load failed!'
  },
  subtitle: {
    id: 'howToEmbedWidgetPage.subtitle',
    defaultMessage: 'Embed a Choicely widget to your website'
  },
  p1: {
    id: 'howToEmbedWidgetPage.p1',
    defaultMessage: 'Use our widget builder to make...'
  },
  getStartedButton: {
    id: 'howToEmbedWidgetPage.getStartedButton',
    defaultMessage: 'Get Started Now!'
  }
}
