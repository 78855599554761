import React, { Component } from 'react'
import classNames from 'classnames'

import Icon from '@/icon'

class Accordion extends Component {
  state = {
    activeItem: null
  }

  onHeaderClick(index) {
    if (index !== this.state.activeItem) {
      this.setState({ activeItem: index })
    } else {
      this.setState({ activeItem: null })
    }
  }

  render() {
    const { headers = [], content = [] } = this.props
    const { activeItem } = this.state

    return (
      <>
        {headers.map((header, index) => {
          const isActive = activeItem === index
          return (
            <div
              key={index}
              className="ba b--moon-gray br2 overflow-hidden mb3"
            >
              <div
                className={classNames({
                  'pa3 bg-light-gray hover-bg-green hover-white bg-animate animate-color': true,
                  'flex items-center justify-between select-none pointer': true,
                  'white bg-green': isActive
                })}
                onClick={() => this.onHeaderClick(index)}
              >
                {header}
                <Icon icon={isActive ? 'angle-up' : 'angle-down'} size="18px" />
              </div>

              {isActive && <div className="bg-white pa3">{content[index]}</div>}
            </div>
          )
        })}
      </>
    )
  }
}

export default Accordion
