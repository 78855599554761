import React, { Component } from 'react'
import DocumentMeta from 'react-document-meta'
import { Link } from 'gatsby'
import config from 'config'

import FormattedMessage from '@/formatted-message'
import FormattedHTMLMessage from '@/formatted-html-message'
import Button from '@/button'

import intlMessages from './messages'
import createContest from './images/how-to-create-contest.png'
import createStep1 from './images/how-to-create-contest__step-1.png'
import createStep12 from './images/how-to-create-contest__step-1.2.png'
import createStep2 from './images/how-to-create-contest__step-2.png'
import createStep3 from './images/how-to-create-contest__step-3.png'

class HowToCreateContestPage extends Component {
  render() {
    const page = {
      meta: {
        property: { 'og:image': createContest }
      }
    }

    return (
      <>
        <DocumentMeta
          title={intlMessages.documentTitle.defaultMessage}
          description={intlMessages.documentDescription.defaultMessage}
          meta={page.meta}
          extend
        />
        <h3>
          <FormattedMessage {...intlMessages.subtitle} />
        </h3>
        <img src={createContest} alt="" className="mb3" />

        <p>
          <FormattedMessage {...intlMessages.p1} />
        </p>
        <p>
          <FormattedMessage {...intlMessages.p2} />
        </p>

        <img src={createStep1} alt="" className="mb3" />
        <img src={createStep12} alt="" className="mb3" />

        <p>
          <FormattedMessage {...intlMessages.p3} />
        </p>
        <img src={createStep2} alt="" className="mb3" />

        <h4>
          <FormattedMessage {...intlMessages['contestOwner.title']} />
        </h4>
        <p>
          <FormattedMessage {...intlMessages['contestOwner.p1']} />
        </p>
        <p>
          <strong>
            <FormattedMessage {...intlMessages['contestOwner.p2.heading']} />
          </strong>{' '}
          <FormattedMessage {...intlMessages['contestOwner.p2.body']} />
        </p>
        <h4>
          <FormattedMessage {...intlMessages['primaryColor.title']} />
        </h4>
        <p>
          <FormattedHTMLMessage {...intlMessages['primaryColor.p1']} />
        </p>

        <h4>
          <FormattedMessage {...intlMessages['contestName.title']} />
        </h4>
        <p>
          <FormattedMessage {...intlMessages['contestName.p1']} />
        </p>

        <h4>
          <FormattedMessage {...intlMessages['description.title']} />
        </h4>
        <p>
          <FormattedMessage {...intlMessages['description.p1']} />
        </p>

        <h4>
          <FormattedMessage {...intlMessages['contestType.title']} />
        </h4>
        <p>
          <FormattedMessage {...intlMessages['contestType.p1']} />
        </p>
        <p>
          <FormattedMessage {...intlMessages['contestType.p2.item1']} />
          <br />
          <FormattedMessage {...intlMessages['contestType.p2.item2']} />
        </p>

        <h4>
          <FormattedMessage {...intlMessages['addContestPhotos.title']} />
        </h4>
        <p>
          <FormattedMessage {...intlMessages['addContestPhotos.p1']} />
        </p>

        <h4>
          <FormattedMessage {...intlMessages['contestCategories.title']} />
        </h4>
        <p>
          <FormattedMessage {...intlMessages['contestCategories.p1']} />
        </p>

        <h4>
          <FormattedMessage {...intlMessages['endDate.title']} />
        </h4>
        <p>
          <FormattedMessage {...intlMessages['endDate.p1']} />
        </p>
        <p>
          <FormattedMessage {...intlMessages['endDate.p2']} />
        </p>

        <h3>
          <FormattedMessage {...intlMessages['advancedOptions.title']} />
        </h3>
        <h4>
          <FormattedMessage
            {...intlMessages['advancedOptions.country.title']}
          />
        </h4>
        <p>
          <FormattedMessage {...intlMessages['advancedOptions.country.p1']} />
        </p>

        <h4>
          <FormattedMessage
            {...intlMessages['advancedOptions.freeVotes.title']}
          />
        </h4>
        <p>
          <FormattedMessage {...intlMessages['advancedOptions.freeVotes.p1']} />
        </p>

        <h4>
          <FormattedMessage
            {...intlMessages['advancedOptions.applauseMode.title']}
          />
        </h4>
        <p>
          <FormattedMessage
            {...intlMessages['advancedOptions.applauseMode.p1']}
          />
        </p>

        <h4>
          <FormattedMessage
            {...intlMessages['advancedOptions.paidVotes.title']}
          />
        </h4>
        <p>
          <FormattedMessage {...intlMessages['advancedOptions.paidVotes.p1']} />
        </p>

        <h4>
          <FormattedMessage
            {...intlMessages['advancedOptions.privacy.title']}
          />
        </h4>
        <p>
          <FormattedMessage {...intlMessages['advancedOptions.privacy.p1']} />
        </p>

        <h4>
          <FormattedMessage
            {...intlMessages['advancedOptions.unvotes.title']}
          />
        </h4>
        <p>
          <FormattedMessage {...intlMessages['advancedOptions.unvotes.p1']} />
        </p>

        <h4>
          <FormattedMessage
            {...intlMessages['advancedOptions.showStatistics.title']}
          />
        </h4>
        <p>
          <FormattedMessage
            {...intlMessages['advancedOptions.showStatistics.p1.title']}
          />
          <br />
          <FormattedMessage
            {...intlMessages['advancedOptions.showStatistics.p1.item1']}
          />
          <br />
          <FormattedMessage
            {...intlMessages['advancedOptions.showStatistics.p1.item2']}
          />
          <br />
          <FormattedMessage
            {...intlMessages['advancedOptions.showStatistics.p1.item3']}
          />
        </p>
        <p>
          <FormattedMessage
            {...intlMessages['advancedOptions.showStatistics.p2']}
          />
        </p>
        <h4>
          <FormattedMessage
            {...intlMessages['advancedOptions.publish.title']}
          />
        </h4>

        <img src={createStep3} alt="" className="mb3" />

        <p>
          <FormattedMessage
            {...intlMessages['advancedOptions.publish.p1.prefix']}
          />{' '}
          <Link className="link" to="/about/how-to-share-and-engage">
            <FormattedMessage
              {...intlMessages['advancedOptions.publish.p1.suffix']}
            />
          </Link>{' '}
          <FormattedMessage
            {...intlMessages['advancedOptions.publish.p1.ending']}
          />
        </p>
        <h4>
          <FormattedMessage
            {...intlMessages['advancedOptions.questions.title']}
          />
        </h4>
        <p>
          <FormattedMessage {...intlMessages['advancedOptions.questions.p1']} />
        </p>
        <Button href={`${config.adminHostUrl}/brand/create`} color="green">
          <FormattedMessage {...intlMessages.getStartedButton} />
        </Button>
      </>
    )
  }
}

export default HowToCreateContestPage
