export default {
  documentTitle: {
    id: 'howToCreateAccountPage.documentTitle',
    defaultMessage: 'How To Create an Account - Choicely'
  },
  documentDescription: {
    id: 'howToCreateAccountPage.documentDescription',
    defaultMessage: 'How To Create an Account - Choicely'
  },
  title: {
    id: 'howToCreateAccountPage.title',
    defaultMessage: 'How to Create an Account'
  },
  imageLoaderError: {
    id: 'howToCreateAccountPage.imageLoaderError',
    defaultMessage: 'Image load failed!'
  },
  subtitle: {
    id: 'howToCreateAccountPage.subtitle',
    defaultMessage:
      'Creating an account on Choicely is fast and free. You can simply sing up on Choicely via Facebook or Google+.'
  },
  p1: {
    id: 'howToCreateAccountPage.p1',
    defaultMessage:
      'Try our Android or iOS applications for free. It´s a zillion times more fun.'
  },
  p2: {
    id: 'howToCreateAccountPage.p2',
    defaultMessage:
      'Download the Choicely app for iOS from the App Store or for Android from Google Play Store. Once the app is installed, tap to open it. Tap Sign Up, then enter your email address and tap Next or tap Log in with Facebook to sign up with your Facebook account.  You can use Choicely through your laptop and devices.'
  },
  getStartedButton: {
    id: 'howToCreateAccountPage.getStartedButton',
    defaultMessage: 'Get Started Now!'
  }
}
