import React, { Component } from 'react'
import DocumentMeta from 'react-document-meta'
import config from 'config'

import FormattedMessage from '@/formatted-message'
import FormattedHTMLMessage from '@/formatted-html-message'
import Button from '@/button'

import intlMessages from './messages'
import createBrand from './images/how-to-create-brand.png'
import createStep1 from './images/how-to-create-brand__step-1.png'
import createStep2 from './images/how-to-create-brand__step-2.png'
import createStep3 from './images/how-to-create-brand__step-3.png'

class HowToCreateBrandPage extends Component {
  render() {
    const page = {
      meta: {
        property: { 'og:image': createBrand }
      }
    }

    return (
      <>
        <DocumentMeta
          title={intlMessages.documentTitle.defaultMessage}
          description={intlMessages.documentDescription.defaultMessage}
          meta={page.meta}
          extend
        />
        <img src={createBrand} alt="" className="mb3" />
        <h3>
          <FormattedMessage {...intlMessages.subtitle} />
        </h3>

        <p>
          <FormattedHTMLMessage {...intlMessages.p1} />
        </p>
        <p>
          <FormattedHTMLMessage {...intlMessages.p2} />
        </p>
        <p>
          <FormattedMessage {...intlMessages['p3.item1']} />
          <br />
          <FormattedMessage {...intlMessages['p3.item2']} />
        </p>

        <img src={createStep1} alt="" className="mb3" />

        <p>
          <FormattedMessage {...intlMessages.p4} />
        </p>

        <ul className="mb3">
          <li>
            <FormattedMessage {...intlMessages['fillIn.item1']} />
          </li>
          <li>
            <FormattedMessage {...intlMessages['fillIn.item2']} />
          </li>
          <li>
            <FormattedMessage {...intlMessages['fillIn.item3']} />
          </li>
          <li>
            <FormattedMessage {...intlMessages['fillIn.item4']} />
          </li>
          <li>
            <FormattedMessage {...intlMessages['fillIn.item5']} />
          </li>
          <li>
            <FormattedMessage {...intlMessages['fillIn.item6']} />
          </li>
        </ul>

        <img src={createStep2} alt="" className="mb3" />
        <p>
          <FormattedMessage {...intlMessages.p5} />
        </p>
        <p>
          <FormattedMessage {...intlMessages.p6} />
        </p>

        <img src={createStep3} alt="" className="mb3" />
        <p>
          <FormattedMessage {...intlMessages.p7} />
        </p>

        <Button href={`${config.adminHostUrl}/brand/create`} color="green">
          <FormattedMessage {...intlMessages.getStartedButton} />
        </Button>
      </>
    )
  }
}

export default HowToCreateBrandPage
