import React, { Component } from 'react'
import DocumentMeta from 'react-document-meta'

import FormattedMessage from '@/formatted-message'
import Layout from '@/layout'
import Container from '@/container'
import Section from '@/section'
import Accordion from '@/accordion'

import HowToCreateAccountPage from '@/how-to-create-account'
import HowToCreateBrandPage from '@/how-to-create-brand'
import HowToEmbedWidgetPage from '@/how-to-embed-widget'
import HowToCreateContestPage from '@/how-to-create-contest'
import HowToShareAndEngagePage from '@/how-to-share-and-engage'
import intlMessages from 'i18n/howToMessages'

class HowToPage extends Component {
  render() {
    const headers = [
      <FormattedMessage
        id="howToPage.createAccount"
        defaultMessage="How to Create an Account"
      />,
      <FormattedMessage
        id="howToPage.createBrandPage"
        defaultMessage="How to Create a Brand Page"
      />,
      <FormattedMessage
        id="howToPage.embedWidget"
        defaultMessage="How to Embed Widget"
      />,
      <FormattedMessage
        id="howToPage.createContest"
        defaultMessage="How to Create a Contest"
      />,
      <FormattedMessage
        id="howToPage.share"
        defaultMessage="How to Share and Engage"
      />
    ]

    const content = [
      <HowToCreateAccountPage />,
      <HowToCreateBrandPage />,
      <HowToEmbedWidgetPage />,
      <HowToCreateContestPage />,
      <HowToShareAndEngagePage />
    ]

    return (
      <Layout>
        <Container>
          <DocumentMeta
            title={intlMessages.documentTitle.defaultMessage}
            description={intlMessages.documentDescription.defaultMessage}
            extend
          />
          <div className="mw7 center">
            <Section
              title={
                <FormattedMessage
                  id="howToPage.title"
                  defaultMessage="How to..."
                />
              }
              subtitle={
                <FormattedMessage
                  id="howToPage.subtitle"
                  defaultMessage="Want to know more about some things?"
                />
              }
            >
              <Accordion headers={headers} content={content} />
            </Section>
          </div>
        </Container>
      </Layout>
    )
  }
}

export default HowToPage
