import React, { Component } from 'react'
import DocumentMeta from 'react-document-meta'
import config from 'config'

import FormattedMessage from '@/formatted-message'
import Button from '@/button'

import intlMessages from './messages'
import loginModal from './images/how-to-create-account.png'

class HowToCreateAccountPage extends Component {
  render() {
    const page = {
      meta: {
        property: { 'og:image': loginModal }
      }
    }

    return (
      <>
        <DocumentMeta
          title={intlMessages.documentTitle.defaultMessage}
          description={intlMessages.documentDescription.defaultMessage}
          meta={page.meta}
          extend
        />

        <h3>
          <FormattedMessage {...intlMessages.subtitle} />
        </h3>
        <img src={loginModal} alt="" className="center db" />

        <p>
          <FormattedMessage {...intlMessages.p1} />
        </p>
        <p>
          <FormattedMessage {...intlMessages.p2} />
        </p>

        <Button
          href={`${config.adminHostUrl}/brand/create?walkthrough=1`}
          color="green"
        >
          <FormattedMessage {...intlMessages.getStartedButton} />
        </Button>
      </>
    )
  }
}

export default HowToCreateAccountPage
