import React from 'react'

import SectionTitle from './section-title'
import SectionSubtitle from './section-subtitle'

const Section = ({ children, title, subtitle }) => (
  <section className="pv6">
    {(title || subtitle) && (
      <div className="tc mb5">
        {title && <SectionTitle text={title} />}
        {subtitle && <SectionSubtitle text={subtitle} />}
      </div>
    )}
    {children}
  </section>
)

export default Section
